<template>
  <div class="container">

    <Loading v-if="loading" style="margin-top: 25px;"/>

    <div class="row mt-5" v-else-if="tools.length <= 0">
      <div class="alert alert-warning" role="alert">
        {{$t('home.empty_message')}}
      </div>
    </div>

    <div class="row mt-5" v-else >

      <div class="col-12 text-center d-flex justify-content-end">
        <div class="btn-group mb-3 w-1">
          <button class="btn btn-secondary" v-on:click="listOrCard = 'card'" :disabled="listOrCard === 'card'">
            <font-awesome-icon :icon="['fas', 'fa-table-cells-large']"/>
          </button>
          <button class="btn btn-secondary" v-on:click="listOrCard = 'list'" :disabled="listOrCard === 'list'">
            <font-awesome-icon :icon="['fas', 'fa-table-list']"/>
          </button>
        </div>
      </div>

<!-- CARD -->
      <div class="col col-md-6 col-xl-3 col-12 mb-4 text-center" v-for="(item, index) in tools" :key="index" v-show="listOrCard === 'card'">
        <div class="card" style="overflow: hidden;">
          <div style="position: relative;" >
            <ImageBlob class="card-image" :data="{image: item.data?.image}"/>
          </div>
          <div class="card-body" style="position: relative; margin-top: 30px;">
            <div class="card-text">
              <h4 class="pb-2">{{item.data.name}}</h4>
              <div class="d-flex">
                <button href="#" class="btn btn-primary w-100 m-1" :disabled="loadingToken.includes(item.id)"  @click="getToken(item.id, item.data.url, item.data.name)"><span v-if="!loadingToken.includes(item.id)">{{$t('home.login')}}</span><BeatLoader color="white" :size="'8px'" style="padding-top: 0px" v-if="loadingToken.includes(item.id)"/></button>
                <button v-if="user.claims.state === 'superadmin'" href="#" class="btn btn-primary w-100 m-1" @click="copyToken(item.id, item.name)"><span v-if="!loadingOnlyToken.includes(item.id)">Token</span> <BeatLoader color="white" :size="'8px'" style="padding-top: 0px" v-if="loadingOnlyToken.includes(item.id)"/></button>
              </div>
            </div>
          </div>
        </div>
      </div>

<!-- LIST -->
      <div class="col-12 mb-3" v-show="listOrCard === 'list'">
        <ul class="list-group">
          <li class="list-group-item p-0 d-flex justify-content-between align-items-center p-2" v-for="(item, index) in tools" :key="index">
            <div>
              <ImageBlob class="list-image rounded" :data="{image: item.data?.image}"/>
            </div>
            <div class="flex-fill ps-3 text-left">
              <h5>{{item.data.name}}</h5>
            </div>
            <div>
              <button href="#" class="btn btn-primary" :disabled="loadingToken.includes(item.id)" @click="getToken(item.id, item.data.url, item.data.name)"><span v-if="!loadingToken.includes(item.id)">{{$t('home.login')}}</span><BeatLoader color="white" :size="'8px'" style="padding-top: 0px" v-if="loadingToken.includes(item.id)"/></button>
              <button v-if="user.claims.state === 'superadmin'" href="#" class="btn btn-primary ms-1" @click="copyToken(item.id, item.name)"><span v-if="!loadingOnlyToken.includes(item.id)">Token</span> <BeatLoader color="white" :size="'8px'" style="padding-top: 0px" v-if="loadingOnlyToken.includes(item.id)"/></button>
            </div>
          </li>
        </ul>
      </div>

    </div>

    <div class="modal" tabindex="-1" ref="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn btn-close" @click="modal.hide()" aria-label="Close" />
          </div>

          <div class="modal-body">
            <textarea class="form-control" aria-label="With textarea" v-model="generatedToken"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modal.hide()">{{$t('settings.close')}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Loading from "./LoadingComponent.vue"
import { httpsCallable } from "firebase/functions";
import { functions } from "../plugins/firebase"
import { query, collection, where, orderBy, getFirestore, getDocs } from "firebase/firestore"
import { mapGetters } from "vuex";
import ImageBlob from "./smallComponents/ImageBlob.vue"
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import { Modal } from "@comlinedev/bootstrap5"

export default {
  name: 'HomeComponent',
  components: {
    Loading,
    ImageBlob,
    BeatLoader
  },  
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  data() {
    return {
      loading: true,
      tools: [],
      error: null,
      show: null,
      loadingToken: [],
      loadingOnlyToken: [],
      listOrCard: localStorage.getItem('listOrCard') || 'card',
      generatedToken: "wait..."
    };
  },
  watch: {
    listOrCard: function(val){
      localStorage.setItem('listOrCard', val)
    }
  },
  methods: {
    async loadData(){
      const q = query(collection(getFirestore(), "tools"), where("users", "array-contains", this.user.data.uid), orderBy("name"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.tools.push({ id: doc.id, data: doc.data()})
      })

      return 'ok'
    },
    getToken(id, url, name){
      this.loadingToken.push(id)
      const newWindow = this.openWindow('', name)
      const getUserTokenFromTool = httpsCallable(functions, 'getUserTokenFromTool');
      getUserTokenFromTool({toolId: id, name: name})
        .then((response)=>{
          const token = response.data
          window.postMessage({ authToken: token }, '*');
          newWindow.location.href = `${url}${token}`
          this.loadingToken = this.loadingToken.filter(function(item) {
              return item !== id
          })
        })
        .catch(err=>{
          console.log(err)
          this.loadingToken = this.loadingToken.filter(function(item) {
            return item !== id
          })
        })
    },
    openWindow (url, name) {
      const newWindow = window.open(url, name)  
      if (!newWindow) {
        alert('Please unblock popups in your browser settings.')
      } else if (window.focus) {
        newWindow.focus()
      }
      return newWindow
    },
		async copyToken(id, name){
      this.loadingOnlyToken.push(id)
      const getUserTokenFromTool = httpsCallable(functions, 'getUserTokenFromTool');
      getUserTokenFromTool({toolId: id, name: name})
        .then((response)=>{
          this.loadingOnlyToken = this.loadingOnlyToken.filter(function(item) {
            return item !== id
          })
          console.log(response.data)
          this.generatedToken = response.data
          this.modal.show()
        })    
        .catch((err) => {
          this.$store.commit('addError', err)
        })

    },
  },
  mounted(){
    this.modal = new Modal(this.$refs.modal)
    this.loadData()
    .then(()=>{
      this.loading = false
    })
  }
}

</script>

<style scoped>
.card{
  height: 100%; 
}
.card-text {
  position: absolute;
  bottom: 0px; 
  width: 100%;
  color: #787878;
  min-height: 120px;
  left: 0px;
  padding: 20px; 
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 79%, rgba(255,255,255,0.742734593837535) 88%, rgba(255,255,255,0) 100%);
}
.list-image {
  max-height: 90px;
  max-width: 100px;
}
.list-group-item:hover {
  background-color: #e9ecef;
}
</style>